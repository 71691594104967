@import "../../../variables.scss";

.directLogin {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mock_web3c {
  position: relative;
  width: 800px;
  height: 320px;
  margin-left: 25px;

  h2 {
    position: absolute;
    left: 0;
    top: -78px;
    font-size: 26px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 690px) {
  .mock_web3c {
    h2 {
      top: -220px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mock_web3c {
    h2 {
      top: -260px;
    }
  }
}

.modal {
  position: relative;
  width: 800px;
  height: 320px;
}

.login_text {
  font-size: 22px;
  letter-spacing: 2px;
  margin-top: -40px;
  color: $lighterFont;
}

.login_3box {
  width: 120px !important;
}