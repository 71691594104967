// Brand Colors
$threeBoxBlue: #1168df;
$threeBoxBlueHover: #0950b0;
$threeBoxBlack: #181F21;
$threeBoxBlackHover: rgb(47, 56, 58);
$threeBoxBlueDeep: #004AA8;
$threeBoxBlueDeeper: #00357D;
$threeBoxBlueLight: #CBDBEE;
$threeBoxBlueLightTransparency: rgba(187, 218, 255, 0.493);

$threeBoxAccentBlue: #021333;
$threeBoxAccentBlueHover: #254277;

// Brand Blue Hues
$threeBoxBlueShade: #C5D7F2;
$threeBoxBlueLightBG: rgb(242, 248, 255);
$threeBoxBlueHue: rgb(251, 254, 255);
$threeBoxBlueHueSecondary: #EDEEEF;

$threeBoxBlueLightShade: #EFF5FE;

$turquoise: #8CFFDE;
$pink: #FF8AFF;
$yellow: #FEFE69;

$backgroundGrey: rgb(248, 248, 248);
$backgroundGreyDark: #dfdfdfa6;

$backgroundHue: rgb(245, 246, 248);

$threeBoxRed: rgb(202, 50, 50);
$threeBoxRedHover: rgb(155, 36, 36);
$threeBoxGreen: rgb(103, 204, 125);


// Font Colors

$lightFont: #6b6b6b;
$lightFontHover: #525252;
$lighterFont: #747677;
$lightestFont: #b1b1b1;


$faintFont: #b8b8b8;
$lightBlueFont: rgb(79, 117, 153);
$medFont: #546983;
// $lighterFont: rgb(177, 177, 177);

// $threeBoxBlack: rgb(1, 12, 22);

//UI Colors

$borderColor: rgb(236, 236, 236);
$borderColorDark:#dcdcdc;

$feedTileGrey: rgb(249, 249, 249);

$defaultProfPic:#006ECC;
// $defaultProfPic: linear-gradient(#d3d3d3, #eeeeee);
// $defaultProfPic: #d3d3d3;

$boxShadow: 0px 4px 10px 4px #00000025,
0 16px 24px 0 #0000002f;



$maxCommentWidth: 600px;
$minCommentWidth: 300px;

$minCommentWidth-mobile: 260px;

$commentPictureDimension: 40px;

$commentPictureDimension-mobile: 50px;
