@import "../../variables.scss";

#landing {
  position: relative;
  background-color: white;
  overflow: hidden;
  transition: all .5s ease;
}

#landing__splash {
  position: relative;
  padding: 11em 10% 4em;
  height: 26em;
  display: inline-block;
  width: 80%;
  display: flex;
  align-items: center;
  background-color: white;

  h1 {
    font-size: 3em;
    font-weight: 800;
  }

  button {
    padding: 1.6em 2.4em;
    border-radius: 3em;
    font-size: .9em;
  }
}

#landing__createProfile {
  width: 60%;
  display: inline-block;
  float: left;
  position: relative;
  animation: fadeIn 1.25s forwards;
  -webkit-animation: fadeIn 1.25s forwards;

  h1 {
    animation: moveHorizontal 1s forwards;
    -webkit-animation: moveHorizontal 1s forwards;
    position: relative;
  }

  p {
    margin-top: .5em;
    font-size: 1.1em;
    animation: moveHorizontal 1s forwards;
    -webkit-animation: moveHorizontal 1s forwards;
    position: relative;
    animation-delay: .1s;
  }

  button {
    animation: moveHorizontal 1s forwards;
    -webkit-animation: moveHorizontal 1s forwards;
    position: relative;
    animation-delay: .2s
  }

  img {
    animation: moveHorizontal 1s forwards;
    -webkit-animation: moveHorizontal 1s forwards;
    position: relative;
    animation-delay: .3s
  }
}

#landing__trustedPartners {
  width: 100vw;
  height: 30em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  button {
    padding: 1.6em 2.4em;
    border-radius: 3em;
    font-size: 1.1em;
  }

  h3 {
    text-align: center;
    z-index: 1;
    display: block;
    margin-bottom: 2em;
    margin-top: 3em;
  }

  #landing__partnerList {
    display: flex;
    z-index: 1;
  }

  .partnerCos {
    flex: 1;
    z-index: 1;
    margin: .5em 4vw;
    width: 30vw;
  }
}

.landing__nav__developers--landing {
  color: white;
  border: solid 1px white;
  border-radius: 6px;
  padding: 6px 10px;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover .landing__nav__developers__icon {
    filter: brightness(10);
  }

  .landing__nav__developers__icon {
    filter: brightness(4);
  }
}

.landing__devs--mobile {
  display: none;
}

#landing__build {
  text-align: center;
  margin: 7em 0 7em 0;
  display: inline-block;
  position: relative;
  width: 100%;

  button {
    padding: 1.6em 2.4em;
    border-radius: 3em;
    font-size: 1.1em;
    margin-top: 2em;
  }

  p {
    margin-top: .5em;
    font-size: 1.1em;
    line-height: 1.4em;
  }

  h2 {
    font-size: 2.8em;
  }
}

.landing {
  p {
    color: #006ECC;
    font-size: 16px;
  }
}

.landing__hero {
  height: 540px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing__hero__copy {
  width: 36vw;
  margin-top: 200px;
  margin-left: 11vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.landing__hero__copy__profilecard--mobile {
  display: none;
}

.landing__hero__copy__tagline {
  color: $threeBoxBlue;
  margin-bottom: 22px;
  font-size: 42px;
}

.landing__hero__copy__text {
  color: $threeBoxBlue;
  font-size: 16px;
  margin-bottom: 40px;
  line-height: 22px;
  width: 350px;
}

.landing__hero__copy__buttons {
  button {
    margin-right: 14px;
  }
}

.landing__hero__image {
  position: relative;
  display: flex;
  justify-content: flex-end;
  // max-width: 45vw;
  max-width: 702px;
  width: 45vw;
  align-self: flex-start;

  img {
    position: absolute;
    height: auto;
    width: auto;
    left: 0;
    top: 0;
  }
}

.landing__overview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
  margin-top: 240px;
  margin-bottom: 200px;
}

.newWalletDapp {
  color: $lighterFont;
}

.main_section_create {
  margin-top: 20px;
}

.landing__overview__diagram {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 74vw;
}

.landing__overview__diagram__wrapper {
  width: 23vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing__overview__diagram__copy {
  text-align: center;
  width: 90%;
  max-width: 220px;
  line-height: 22px;
}

.landing__overview__tagline {
  h2 {
    color: $threeBoxBlue;
    font-size: 32px;
  }

  margin-bottom: 100px;
}

.landing__overview__logo {
  margin-bottom: 30px;

  img {
    width: 120px;
  }
}

.landing__overview__diagram__graphic {
  width: 14vw;
  margin-bottom: 40px;
}

.landing__developers {
  background-color: $threeBoxBlue;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 126px 0 146px 0;
  margin-bottom: 400px;
}

.landing__developers__buttonWrapper {
  width: 23vw;
  min-width: 260px;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  margin-bottom: 60px;
}

.landing__developers__buttonWrapper__button {
  background-color: transparent;
  color: white;
  border: 1px solid white;

  &:hover {
    background-color: white;
    color: $threeBoxBlue;
  }
}

.landing__developers__header {
  color: #CFE3FF;
  margin-bottom: 40px;
  letter-spacing: 2px;
}

.landing__developers__tagline {
  color: white;
  margin-bottom: 20px;
  font-size: 32px;
  text-align: center;
  width: 80vw;
}

.landing__developers__copy {
  color: white !important;
  margin-bottom: 40px;
}

.landing__developers__graphic {
  position: relative;
  width: 100vw;
  height: 400px;
  min-width: 800px;
  margin-bottom: 60px;
}

.profPic1 {
  position: absolute;
  left: -1%;
  top: 10px;
}

.profPic2 {
  position: absolute;
  left: 3%;
  top: 140px;
}

.profPic3 {
  position: absolute;
  left: -2%;
  top: 280px;
}


.profPic4 {
  position: absolute;
  left: 14%;
  top: 70px;
}

.profPic5 {
  position: absolute;
  left: 10%;
  top: 220px;
}


.profPic6 {
  position: absolute;
  left: 28%;
  top: 10px;
}

.profPic7 {
  position: absolute;
  left: 33%;
  top: 270px;
}

.profPic8 {
  position: absolute;
  left: 42%;
  top: 40px;
}

.profPic9 {
  position: absolute;
  left: 57%;
  top: 80px;
}

.profPic10 {
  position: absolute;
  left: 26%;
  top: 140px;
}

.profPic11 {
  position: absolute;
  left: 46%;
  top: 170px;
}

.profPic12 {
  position: absolute;
  left: 62%;
  top: 235px;
}

.profPic13 {
  position: absolute;
  left: 76%;
  top: 113px;
}

.profPic14 {
  position: absolute;
  left: 69%;
  top: 00px;
}

.profPic15 {
  position: absolute;
  left: 77%;
  top: 260px;
}

.profPic16 {
  position: absolute;
  left: 88%;
  top: 190px;
}

.profPic17 {
  position: absolute;
  left: 86%;
  top: 10px;
}

.profPic18 {
  position: absolute;
  left: 95%;
  top: 90px;
}

.profPic19 {
  position: absolute;
  left: 100%;
  top: 250px;
}

.profPic20 {
  position: absolute;
  left: 105%;
  top: 00px;
}

.landing__developers__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 90px;
}

.landing__developers__footer__links__logo {
  width: 80px;
  // margin-right: 50px;
}

.landing__developers__footer__links__linkWrapper {
  p {
    color: white !important;
    margin-right: 30px;
  }
}

.landing__developers__footer__buttons--signIn:hover {
  background-color: transparent !important;
}

.landing__developers__footer__links__logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes moveHorizontal {
  from {
    left: -5vw;
  }

  to {
    left: 0px;
  }
}

#landing__profileCard {
  width: 40%;
  display: inline-block;
  animation: fadeIn 1.25s forwards;
  -webkit-animation: fadeIn 1.25s forwards;
  z-index: 1;
}

@keyframes moveVertical {
  from {
    top: -.5em;
  }

  to {
    top: 0px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#landing__profileCard--margin {
  margin: 2.5em 0 2.5em 6vw;
  animation: moveVertical 1.25s forwards;
  -webkit-animation: moveVertical 1.25s forwards;
  position: relative;
}

@keyframes bounce {
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}

@keyframes scroll {
  0% {
    left: 800px;
  }

  100% {
    left: -800px;
  }
}

.animateOnEvent .ae-1,
.animateOnEvent .fast .ae-1.fast,
.animateOnEvent .ae-1.fast {
  -webkit-animation-delay: 10ms;
  animation-delay: 10ms;
}

.animated [class*="ae-"] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.2, 1);
}

.animateOnEvent .ae-1 {
  -webkit-animation-delay: 10ms;
  animation-delay: 10ms;
}

#landing__footer {
  width: 100%;
  height: 30em;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-bottom: 3em;

  ul {
    width: 80%;
    display: flex;
    align-items: center;

    li {
      width: 50%;
      display: flex;
      align-items: center;
    }
  }

  a {
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
  }

  #landing__mailChimp {
    display: flex;
    align-items: center;
    z-index: 1;
    width: 40vw;
    justify-content: center;
    margin-top: 4em;

    input {
      display: inline-block;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-style: none;
      height: 44px;
      max-width: 70%;
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #414E66;
      color: white;

      &::placeholder {
        color: white;
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      height: 44px;
      padding: 0 20px;
    }
  }
}

#footer__links {
  z-index: 1;
  display: flex;
  width: 30vw;
  justify-content: space-between;
  margin-top: 1.5em;

  #footer__scaleLogo {
    zoom: 1.5;
    -moz-transform: scale(1.5);

    #logo__icon {
      margin-left: 0;
    }
  }
}

#footer__socialLinks {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 35%;
}

#footer__bg {
  min-width: 100%;
  position: absolute;
  bottom: -4px;
  z-index: 0;
  overflow: hidden;
}

#footer__info {
  z-index: 1;
  display: flex;
  width: 10vw;
  justify-content: space-between;

  p {
    color: white;
    opacity: .7;
  }
}

.footer__socialIcons {
  width: 24px;
  z-index: 1;
}

// 767px ?
@media only screen and (max-width: 600px) {
  .landing__hero {
    margin: 0;
    justify-content: center;
    height: fit-content;
  }

  .landing__hero__copy {
    width: 80vw;
    margin: 100px 0 0 0;
    align-items: center;
  }

  .landing__hero__copy__profilecard--mobile {
    display: block;
    width: 65vw;
    height: auto;
  }

  .landing__hero__image {
    display: none;
  }

  .landing__hero__copy__tagline {
    margin-top: 32px;
    text-align: center;
    margin-bottom: 26px;
    font-size: 32px;
    line-height: 42px;
  }

  .landing__hero__copy__text {
    margin-bottom: 22px;
    text-align: center;
    width: 80vw;
  }

  .landing__devs--mobile {
    background-color: $threeBoxBlue;
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    padding: 64px 0 68px 0;

    h2 {
      color: white;
      width: 80vw;
      text-align: center;
      font-size: 22px;
      margin-bottom: 30px;
    }
  }

  .landing__overview {
    margin-top: 98px;
    height: fit-content;
    margin-bottom: 100px;
  }

  .landing__overview__tagline {
    width: 80vw;
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 28px;
    }
  }

  .landing__overview__diagram {
    width: 80vw;
    flex-direction: column;
    height: fit-content;
  }

  .landing__overview__diagram__wrapper {
    width: 58vw;
    min-height: 200px;
    margin-bottom: 20px;
  }

  .landing__overview__diagram__graphic {
    width: 40vw;
    margin-bottom: 24px;
  }

  .joinDiscord {
    margin-right: 0 !important;
  }

  .landing__developers {
    padding: 80px 0 80px 0;
  }

  .landing__developers__tagline {
    width: 80vw;
    font-size: 28px;
    text-align: center;
  }

  .landing__developers__copy {
    width: 80vw;
    text-align: center;
  }

  .landing__developers__graphic {
    min-width: 165vw;
    margin-bottom: 30px;
  }

  .landing__developers__footer__buttons--signIn {
    margin-right: 5vw;
  }

  .landing__overview__logo {
    img {
      width: 20vw;
      opacity: .5;
    }
  }

  .landing__hero__copy__buttons {
    display: none;
  }

  #landing {
    p {
      font-size: 1em;
    }
  }

  #threeBoxGraphic {
    display: none;
  }

  #threeBoxGraphic2 {
    display: none;
  }

  #logo__icon {
    margin-left: 5vw;
  }

  #footer__links {
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    width: 40vw;
  }

  .footer__socialIcons {
    width: 7vw;
  }

  #footer__info {
    width: 28vw;
    margin-top: 1em;
  }

  #footer__socialLinks {
    width: 90%;
    margin-top: 3em;
  }

  #landing__splash {
    margin: 5em 0 6em 0;
    height: fit-content;
    width: fit-content;
    flex-direction: column-reverse;
    padding: 2em;
  }

  #consensys {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;

    p {
      margin-top: 0 !important;
    }

    img {
      width: 5.8em;
    }
  }

  .removeBottomMargin {
    margin-bottom: 0 !important;
  }

  #landing__createProfile {
    width: fit-content;
    display: block;
    animation: fadeIn 1.25s forwards;
    -webkit-animation: fadeIn 1.25s forwards;
    float: right;

    h1 {
      animation: moveHorizontal 1s forwards;
      -webkit-animation: moveHorizontal 1s forwards;
      font-size: 1.8em;
      margin-bottom: .8em;
      margin-top: .5em;
    }

    p {
      font-size: 1.3em;
      animation: moveHorizontal 1s forwards;
      -webkit-animation: moveHorizontal 1s forwards;
      position: relative;
      animation-delay: .1s;
    }

    button {
      animation: moveHorizontal 1s forwards;
      -webkit-animation: moveHorizontal 1s forwards;
      position: relative;
      animation-delay: .2s;
    }

    img {
      animation: moveHorizontal 1s forwards;
      -webkit-animation: moveHorizontal 1s forwards;
      position: relative;
      animation-delay: .3s
    }
  }

  .profileCard {
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    min-width: 10em;
    min-height: 18em;
    background: white;
    width: 16em;
  }

  #landing__profileCard {
    width: fit-content;
    margin: 4vh 0 5vh 0;
  }

  #landing__profileCard--margin {
    margin: 0 auto;
  }

  #landing__button--center {
    display: flex;
    justify-content: center;
  }

  #landing__createProfileButton {
    margin-top: 0;
  }

  #landing__trustedPartners {
    height: 12em;

    h3 {
      margin: 1em 0 1em;
    }
  }

  #landing__partnerList {
    flex-direction: column;
    height: fit-content;

    .partnerCos {
      -ms-flex: 1 1;
      flex: 1 1;
      z-index: 1;
      margin: 1em 4vw;
      width: 70vw;
    }
  }

  #landing__footer {
    height: 22em;

    #landing__mailChimp {
      margin-top: 2em;
    }
  }

  #trustedPartners--bg {
    min-height: 30em;
  }

  #landing__build {
    margin-top: 6em;
    margin-bottom: 8em;

    h2 {
      font-size: 1.6em;
    }
  }

  .build__section {
    margin-top: 2em;
    margin-bottom: 4em;
    flex-direction: column-reverse;
    justify-content: center;
    height: fit-content;

    .build__section__text {
      height: 14em;

    }

    .build__section__content {
      height: 10em;
      text-align: center;
      padding: 1em;
      margin: 2em 0 0 0;
    }

    .build__graphic__profiles {
      width: 100%;
      height: 22em;
      margin-top: 3em;
    }

    .build__graphic__threeBox {
      width: 100%;
      height: 13em;
      margin-top: 5em;
      justify-content: center;
    }

    #threeboxIllustration {
      margin: 0;
      width: 79%;
    }
  }

  .landing__createProfile--text {
    display: flex;
    text-align: center;
  }

  .landing__createProfile--subtext {
    display: none;
  }

  #consensys {
    display: none;
  }

  #profileCard__username {
    margin-bottom: 1.2em;
    margin-top: 1.3em;
  }

  #Michael {
    position: absolute;
    top: 0;
    left: 11.5vw;
    z-index: 0;
  }

  #Christian {
    position: absolute;
    top: 4em;
    left: 19.5vw;
    z-index: 1;
  }

  #Cristobal {
    position: absolute;
    top: 8em;
    left: 27.5vw;
    z-index: 2;
  }
}