@import "../../../variables.scss";

#profile {
  width: calc(50vw - 350px);
  min-width: 330px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    color: $lightFont;
  }
}

#profile__fixed {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.profile__user__emoji {
  height: 24px;

  img {
    height: 24px;
    margin-left: 6px;
  }
}

.profile__basic__wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.publicProfile {
  margin-top: 290px;
}

.profile__basic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 34px;
  margin-bottom: 12px;
  width: 230px;
}

.profile__basic__emoji {
  font-size: 26px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__basic__description {
  width: 200px;
  line-height: 20px;
  height: fit-content;
  word-break: normal;
}

.profile__coverPhoto {
  position: absolute;
  top: 69px;
  width: 100vw;
  height: 174px;
  background-color: $threeBoxBlueShade;
  z-index: -1;
  object-fit: cover;

  transition: all ease .5s;
}

.profile__category {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 20px 0;
  width: 200px;
}

.profile__category--mobile {
  display: none;
}

.profile__category__sectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile__category__header {
  // margin-bottom: 30px;
  // border-bottom: 1px solid $borderColor;
  padding: 20px 0 14px 20px;
  display: flex;
  justify-content: space-between;

  h5 {
    font-size: 18px;
    color: $lighterFont;
    font-weight: 400;
  }
}

.contacts_page {
  justify-content: flex-start !important;
  align-items: center;
}

.contacts_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $lighterFont;
  }

  p {
    font-size: 20px;
    color: $lighterFont;
  }
}

.contact_list {
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(1, 100% [col-start]);
  row-gap: 20px;
  z-index: 4;
}

.contact_tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h3 {
    display: flex;
    margin-bottom: 0;
    margin-left: 20px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .threeboxProfileHover__style_boxAddressWrap {
    width: 70%;
    font-family: 'Lato', sans-serif !important;
  }
}

.empty_contact_tile {
  max-width: 694px;
  min-width: 694px;
  height: 44px;
  padding: 10px 10px;
  left: calc(50vw - 350px);
  border: 1px solid #ececec;
  border-radius: 6px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease .5s;
  margin-bottom: 20px;
  background-color: white;

  p {
    color: $lightestFont;
    font-size: 16px;
  }
}

.contact_tile_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact_tile_info_image {
  width: 60px !important;
  height: 60px !important;
  min-width: 60px !important;
  min-height: 60px !important;
  background-color: white;
  border-radius: 50%;
  object-fit: cover;
}

.profile__category__divider {
  width: 94%;
  margin-left: 3%;
  border-bottom: 1px solid $borderColor;
  margin-bottom: 30px;
  align-self: center;
}

.profile__category__editLink {
  margin-right: 40px;
  color: $threeBoxBlue;
}

.profile__category__section {
  font-size: 1em;
  margin-bottom: 8px;
  color: $lightestFont;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 14px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    color: $lightFont;

    .profile__category__tabIcon__wrappper {
      img {
        opacity: 1 !important;
      }
    }
  }
}

.profile__category__tabIcon--activity--mobile {
  height: 21px;
  margin-right: 6px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.profile__category__tabIcon--details--mobile {
  height: 20px;
  margin-right: 6px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.profile__category__tabIcon--collectibles--mobile {
  height: 20px;
  margin-right: 6px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.profile__category__tabIcon__wrappper {
  width: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile__category__tabIcon--activity {
  height: 20px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.profile__category__tabIcon--details {
  height: 16px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.profile__category__tabIcon--collectibles {
  height: 16px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.profile__category__tabIcon--contacts {
  height: 20px;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  opacity: .5;
}

.active {
  background-color: $backgroundGreyDark !important;
  color: $threeBoxBlue !important;

  img {
    filter: none;
    opacity: 1;
  }
}

.active.sc-user-input {
  background-color: transparent !important;
}

.sc-user-input--button button {
  &:hover {
    background-color: transparent;
  }
}

.profile__links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0 30px 0;
  width: 110px;
}

.profile__links__copy {
  background-color: transparent;
  border: none;
  padding: 5px 9px;
  border: 1px solid $lightestFont;
  border-radius: 6px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 126px;
  transition: all ease .3s;
  margin-top: 110px;

  p {
    min-width: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a2a2a2 !important;
  }

  &:hover {
    background-color: $threeBoxBlueLightShade;
    background-color: white;
  }
}

.profile__links__copy__icon {
  width: 14px !important;
  margin: 0 10px 0 0;
}

#hiddenProfileURL {
  display: none;
}

.profile__user__info {
  padding: 30px 50px;
  max-width: 230px;
  min-width: 230px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    text-align: left;
    font-size: 14.5px;
  }

  transition: all ease .5s;
}

.loggedInMargin {
  margin-top: -69px;
}

.public__about {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}

.publicProfile__basic--mobile {
  display: none;
}

.public__about__header {
  color: $lightestFont;
  font-size: 16px;
  margin: 0 0 10px 0;
}

.public__about__field {
  display: flex;
  align-items: center;
  margin: 6px 0 5px 0;
  justify-content: flex-start;
  width: 300px;

  p {
    color: $lightFont;
    width: 194px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.pubContent {
  transition: all ease .5s;
}

.onMyProfile {
  margin-top: -69px;
}

.publicStatusUpdate {
  padding: 0.3em 10px;
  margin-top: 230px;
}

.publicStatusUpdate-isLoggedIn {
  padding: 0.3em 10px;
  margin-top: 161px;
}

.publicStatusUpdate--bannerMargin {
  margin-top: 284px !important;
}

.public_contacts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.public_contacts_list {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.public_contacts_list_profiles {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.public_contacts_list_profiles_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  margin-left: -22px;
  border: 2px solid $backgroundHue;
  object-fit: cover;
}

.first {
  margin-left: 0;
}

.public_contacts_mutual {
  margin-bottom: 20px;
}

.public_contacts_add {
  width: 100%;

  button {
    width: 100%;
  }
}

#profile__page {
  height: calc(100% - 133px);
}

#profile__contents {
  display: flex;
  height: 100%;
}

#profile__footer {
  width: 300px;
  margin-top: 33px;
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  z-index: 1;
  background-color: $backgroundHue;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#profile__footer__mobile {
  display: none;
}

#profile__footer__contents {
  display: flex;
  justify-content: space-between;
  width: 160px;

  p {
    font-size: .8em;
    display: inline-block;
    margin: 1vh 0 1vh 0;
    opacity: .5;
  }

  a {
    font-size: .8em;
    display: inline-block;
    margin: 1vh 0 1vh 0;
    opacity: .5;
  }
}

.profile__user__picture {
  border-radius: 100%;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  margin-top: 142px;
  object-fit: cover;
  border: 6px solid white;
  background-color: $defaultProfPic;
}

#profile__network__networkLogo {
  height: 19px;
  margin-right: .6em;
}

#profile__network {
  float: left;
  clear: both;
  margin-bottom: 22px;
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 600;
    color: #a0a0a0;
  }
}

.profile__category__field {
  display: flex;
  align-items: center;
  margin: 5px 0 18px 0;

  display: flex;
  justify-content: flex-start;
  width: 300px;

  padding-left: 40px;

  p {
    color: $lightFont;
    width: 194px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  a {
    color: $threeBoxBlue;
  }
}

.profile__category__field--private {
  width: fit-content !important;
  display: flex !important;
  // align-items: center;
  padding-right: 8px;
}

.profile__user__name {
  font-size: 1.4em;
  white-space: nowrap;
  color: $threeBoxBlue;
}

.profile__user__name__add {
  color: $lighterFont;
}

.profile__category__field__icon {
  height: 19px;
  width: 22px;
  display: inline-block;
  margin-right: 14px;
  opacity: .7;
}

.profile__category__verified__icon {
  width: 16px;
  height: 16px;
}

.profile__category__field__verified {
  max-width: 194px;
  width: fit-content !important;
  padding-right: 8px;
  color: $threeBoxBlue;
  font-size: 14.5px;
}

#profile__github {
  display: inline-block;
}

#profile_edit_button {
  margin-top: 1vh;
  display: inline-block;
}

#profileFooter_githubIcon {
  height: 18px;
  display: inline-block;
}

#profileFooter_email {
  display: inline-block;
  height: 12px;
}

#profileFooter__email__icon {
  height: 12px;
  display: inline-block;
  align-content: left;
  margin-right: .6em;
  opacity: .7;
}

.followActionButton {
  width: 108px;
  min-height: 50px;
  transition: none;

  img {
    width: 18px !important;
    margin-left: 0;
  }
}

.public_contacts_add {
  .followActionButton {
    img {
      margin-right: 10px;
    }
  }
}

.loadingProfile {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.281);
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadingProfile__Spinner {
  width: 3em;
  height: 3em;
  z-index: 1;
}

.profile__details__category {
  width: 100%;
  margin-bottom: 14px;
}

@media only screen and (max-width: 600px) {
  .profile__coverPhoto {
    z-index: 0;
  }

  .profile__category__divider {
    width: 90vw;
    margin-left: 5vw;
  }

  .profile__details__category {
    width: 100%;
    margin: 18px 0 0 0;
    background-color: white;
    border-bottom: 1px solid $borderColor;
    padding-bottom: 18px;
  }

  .publicStatusUpdate {
    padding: 0;
    margin: 0;
    height: 80px;
  }

  #profile {
    position: static;
    height: auto;
    width: 100vw;
    text-align: center;
    align-items: flex-start;
  }

  .profile__category__header {
    padding: 30px 0 20px 30px;
    border-top: solid 1px $borderColor;
  }

  .publicStatusUpdate--bannerMargin {
    margin-top: 0 !important;
  }

  #profile__fixed {
    position: static;
    width: 100vw;
    align-items: center;
  }

  #profile__contents {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .publicProfile__basic--mobile {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-top: 50px;
  }

  .profile__user__name {
    font-size: 1.4em;
  }

  .profile__links__copy {
    display: none;
  }

  .profile__category__field {
    padding-left: 30px;
  }

  .profile__user__info {
    max-width: 100vw;
    min-width: 100vw;
    padding: 50px 0 0 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: static;
    align-items: center;
    background-color: white;
  }

  .addBorderBottom {
    border-bottom: 1px solid #E4E4E4;
  }

  .profile__category {
    display: none;
  }

  .public_contacts_list_count {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

  .profile__category--mobile {
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
    height: fit-content;
    border-bottom: solid 1px $borderColor;
    background-color: white;
  }

  .profile__category__section {
    font-size: 14px;
    margin-bottom: 0;
    color: $lightestFont;
    background-color: white;
    border-radius: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .borderRight {
    border-right: 1px solid $borderColor;
  }

  .profile__category__sectionWrapper {
    background-color: white;
    flex-direction: row;
    padding: 0;
    height: 60px;
  }

  #profile__private {
    margin-bottom: 2vh;
    margin-top: 2vh;
  }

  .profile__user__picture {
    margin-top: 125px;
    z-index: 2;
  }

  .active {
    background-color: white !important;
    color: $threeBoxBlue !important;
  }

  #profile__footer {
    display: none;
  }

  #profile__footer__mobile {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    background-color: #E4E4E4;
    text-align: center;
    border-top: 1px solid #E4E4E4;
  }

  #profile_edit {
    position: relative;
    bottom: 0;
    margin-top: .5em;
    margin-bottom: 1.5em;
  }

  .profile__basic__description {
    width: 100%;
  }

  .contact_list {
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .contact_tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h3 {
      margin-left: 12px;
      text-align: left;
      font-size: 14px;
    }

    a {
      max-width: calc(100% - 86px);
    }
  }

  .contact_tile_info_image {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
  }

  .followActionButton {
    padding: 4px 10px;
    font-size: 12px;
    width: 86px;
    min-height: 40px;
  }

  .empty_contact_tile {
    max-width: 100%;
    min-width: 100%;
    height: 64px;
    box-sizing: border-box;
  }
}