@import './variables.scss';

// turn off button highligh when not in accessibility mode
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) span:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  position: relative;
}

html {
  min-width: 100%;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: $threeBoxBlack;
  background-color: $backgroundHue;
  font-weight: 400;
  height: calc(100% - 69px);
  width: 100%;
}

button {
  background-color: $threeBoxBlack;
  border-radius: 3px;
  padding: 14px 20px;
  color: white;
  transition: background-color .4s ease;
  font-family: 'Lato', sans-serif;
  border: solid 2px $threeBoxBlack;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: .77px;

  img {
    margin-left: 10px;
  }

  &:disabled {
    background: transparent;
    border: solid 1px #7a7a7a;
    color: #7a7a7a;
    box-shadow: none;
  }

  &:disabled:hover {
    background: transparent;
    border: solid 1px #7a7a7a;
    color: #7a7a7a;
    box-shadow: none;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $threeBoxBlackHover;
    border-color: $threeBoxBlackHover;
  }
}

.primaryMarketing {
  font-size: 20px;
  letter-spacing: .77px;
  width: 235px;
  display: flex;
  justify-content: space-between;
  height: 62px;
  border: 3px solid $threeBoxBlack;
  box-shadow: 0 3px 5px 0 #00000025, 0 4px 6px 0 #0000002f;

  &:hover {
    background-color: $threeBoxBlackHover;
    border-color: $threeBoxBlackHover;
  }
}

.popupChat {
  min-height: 0 !important;
}

.secondary {
  background-color: transparent;
  color: $threeBoxBlack;
  font-size: 20px;
  letter-spacing: .77px;
  width: 235px;
  display: flex;
  justify-content: space-between;
  height: 62px;
  border: 3px solid $threeBoxBlack;

  &:hover {
    background-color: $backgroundHue;
  }
}

.secondaryBlue {
  background-color: transparent;
  color: $threeBoxBlue;
  border-color: $threeBoxBlue;

  &:hover {
    background-color: $backgroundHue;
  }
}

.popupChat {
  min-height: 0 !important;
}

.blueButton {
  border-color: $threeBoxBlue;
  color: $threeBoxBlue;

  &:hover {
    background-color: transparent;
    border-color: $threeBoxBlue;
    color: $threeBoxBlue;
  }
}

.clearButton {
  background-color: transparent;
  border-radius: 0;
  padding: 14px 20px;
  transition: background-color .4s ease;
  font-family: 'Lato', sans-serif;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: transparent;
    border: none;
  }
}

.textButton {
  background-color: transparent;
  border-radius: 5px;
  padding: 0;
  transition: background-color .4s ease;
  font-family: 'Lato', sans-serif;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $threeBoxBlue;
  font-size: 16px;

  &:hover {
    background-color: transparent;
    border: none;
  }
}

.secondaryButton {
  background: transparent;
  border: solid 1px $threeBoxBlue;
  outline-offset: -1px;
  color: $threeBoxBlue;
  transition: background-color .8s ease;

  &:hover {
    color: white;
    background-color: $threeBoxBlue;
    border: solid 1px transparent;
    transition: background-color .8s ease;
  }
}

.tertiaryButton {
  background-color: transparent;
  border: solid 1px #474747;
  color: #7a7a7a;
  box-shadow: none;
  transition: background-color .8s ease;

  &:hover {
    color: white;
    background-color: #474747;
    border: solid 1px transparent;
    transition: background-color .8s ease;
  }
}

.fourthButton {
  color: white;
  font-size: .8em;
  color: white;
  background: rgba(0, 0, 0, 0.404);
  padding: .8em 1.3em;
  -webkit-transition: background-color .4s ease;
  -o-transition: background-color .4s ease;
  transition: background-color .4s ease;
}

.unstyledButton {
  padding: 0;
  color: $threeBoxBlue;
  background-color: transparent;
  -webkit-transition: color .4s ease;
  -o-transition: color .4s ease;
  transition: color .4s ease;
  border: none;

  &:hover {
    color: $threeBoxBlueHover;
    padding: 0;
    background-color: transparent;
    border: none;
  }
}

.outlineButton {
  background-color: transparent;
  border: solid 1px #7a7a7a;
  color: #7a7a7a;
  box-shadow: none;
  padding: 15px 20px;

  &:hover {
    border: solid 1px #7a7a7a;
    color: #7a7a7a;
    background-color: #eaeaea !important;
  }
}

.Main {
  background: #29B6AF;
}

.Ropsten {
  background: #FF4A8D;
}

.Kovan {
  background: #7057FF;
}

.Rinkeby {
  background: #F6C343;
}

.Unknown {
  background: black;
}

button:hover {
  cursor: pointer;
}

input,
textarea,
select,
li,
a,
p {
  color: $threeBoxBlack;
  font-weight: 400;
}

input,
textarea,
select,
button,
li,
a,
p {
  text-decoration: none;
  font-size: 14px;
  list-style: none;
  line-height: 18px;
}

button {
  font-weight: 800;
}

div,
textarea,
input,
li,
a,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Lato', sans-serif;
  color: $threeBoxBlack;
  margin: 0;

  &::placeholder {
    font-family: 'Lato', sans-serif;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
}

ul {
  margin: 0;
}

input:focus {
  border-radius: 5px;
}

.mobile {
  display: none !important;
}

// for preventing body from moving when modals are open
.fixBody {
  position: fixed;
}

// web3connect modal
.dALBps {
  position: fixed !important;
}

.signInFromPublicProfileBanner {
  width: 100vw;
  height: 54px;
  background: $threeBoxBlue;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;

  .signInFromPublicProfileBanner__wrapper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    color: white;
    width: fit-content;
  }
}

.hideBanner.signInFromPublicProfileBanner {
  display: none;
}

.hideBanner {
  top: -54px;
  background: white;

  button {
    background-color: white;
  }
}

.webThreeBanner__close {
  cursor: pointer;
  position: absolute;
  right: 32px;
}

.header {
  font-size: 1.1em;
  color: #a8a8a8;
  font-weight: 500;
}

.subheader {
  color: #a8a8a8;
  margin-bottom: .5em;
}

.subtext {
  color: #a8a8a8;
}

.bold {
  font-weight: 700;
}

.grey {
  color: #a8a8a8;
}

.blue {
  color: #006ECC;
}

.light {
  color: #6b6b6b;
}

.lighter {
  color: #9b9b9b;
}

.white {
  color: white;
}

.lightOpacity {
  opacity: .6;
}

.clearProfPic {
  background-color: white !important;
}

.noTopMargin {
  margin-top: 0 !important;
}

@media only screen and (max-width: 600px) {
  // .webThreeBanner {
  //   display: none;

  //   p {
  //     margin-left: 4vw;
  //     line-height: 15px;
  //     width: 50%;
  //   }
  // }

  .signInFromPublicProfileBanner {
    height: 64px;
    width: 100vw;
    background: $threeBoxBlue;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;

    p {
      color: white;
      width: 85%;
    }

    .signInFromPublicProfileBanner__wrapper {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .webThreeBanner__close {
    position: relative;
    width: 5% !important;
    right: 0;
  }

  // web3connect modal
  .kaYREe {
    margin-top: -90px !important;
  }

  .header {
    display: none;
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

.popupChat {
  z-index: 5 !important;
}

.sc-user-input--like-icon-wrapper {
  background-color: white !important;
}